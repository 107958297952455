import React from 'react';
import { Helmet } from 'react-helmet';
import Home from './Home';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <Helmet>
        <title>佳恒新能源官网</title>
        <meta name="description" content="佳恒新能源官方网站" />
        <meta name="keywords" content="佳恒,新能源,电动自行车" />
      </Helmet>
      <Home />
    </div>
  );
};

export default App;
