import React from 'react';
import { CSSTransition } from 'react-transition-group';

class Content10 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: props.isMobile,
    };
  }

  onClick = () => {
    window.open(this.props.dataSource.Content.children.url.children);
  };

  markerEnter = () => {
    if (this.leaveTimer) {
      clearTimeout(this.leaveTimer);
    }
    this.setState({
      showInfo: true,
    });
  };

  markerLeave = () => {
    if (this.leaveTimer) {
      clearTimeout(this.leaveTimer);
    }
    this.leaveTimer = setTimeout(() => {
      this.setState({
        showInfo: false,
      });
    }, 300);
  };

  componentWillUnmount() {
    if (this.leaveTimer) {
      clearTimeout(this.leaveTimer);
    }
  }

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;

    return (
      <div {...props} {...dataSource.wrapper}>
        <div
          {...dataSource.Content}
          onMouseEnter={this.markerEnter}
          onMouseLeave={this.markerLeave}
          onClick={this.onClick}
          onTouchEnd={this.onClick}
        >
          <div {...dataSource.Content.children.icon}>
            <img src={dataSource.Content.children.icon.children} alt="img" />
          </div>
          <div {...dataSource.Content.children.iconShadow}>
            <img
              src={dataSource.Content.children.iconShadow.children}
              alt="img"
            />
          </div>
        </div>
        <CSSTransition
          in={this.state.showInfo}
          timeout={300}
          classNames="map-tip"
          unmountOnExit
        >
          <div className="map-tip">
            <h2>{dataSource.Content.children.title.children}</h2>
            <p>{dataSource.Content.children.content.children}</p>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default Content10;
